import React from "react";
import { Col, Row } from "react-bootstrap";
import { CgCPlusPlus } from "react-icons/cg";
import {
  DiJavascript1,
  DiReact,
  DiNodejs,
  DiAndroid,
  DiPython,
  DiGit,
  DiJava,
} from "react-icons/di";
import {
  SiCsharp,
  SiFirebase,
  SiKotlin,
  SiPostgresql,
  SiSqlite,
  SiAwslambda,
  SiAmazondynamodb,
  SiAzuredevops,
  SiAzurefunctions,
  SiPytorch,
  SiTypescript,

} from "react-icons/si";

function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <CgCPlusPlus />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiJavascript1 />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiNodejs />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiReact />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiGit />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiFirebase />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiPostgresql />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiPython />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiJava />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiAndroid />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiKotlin />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiCsharp />
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <SiSqlite />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiAwslambda />
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <SiAmazondynamodb />
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <SiAzuredevops />
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <SiAzurefunctions />
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <SiPytorch />
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <SiTypescript />
      </Col>


    </Row>
  );
}

export default Techstack;
